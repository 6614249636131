import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import '../src/css/css/bootstrap.min.css'
import '../src/css/css/login.css'
import "../src/css/css/material.css";
import "../src/css/css/icons.css";
import '../src/css/css/custom.css';
import '../src/css/css/calendar.css';
import '../src/css/css/login.css';
import { Provider } from "react-redux";
import store from "./Store/calender";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
  <App />
</Provider>
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
