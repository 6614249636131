const initalState = {
  appointments: [],
  columns: [],
  showModel: false,
  showPasswordModel:false,
  showAppointmentModel: false,
  showContactSupport: false,
  showDeleteModel: false,
  showQuickpatient: false,
  showConfirmschedule: false,
  physicianId: '',
  viewAppointment: false,
  notification: false,
  showSidebar:false,
  showFilterBar:false,
  showcolumn:false,
  patientProfile:true,
  showSetAvailability:false,
  updateDatesModel:false,
  accessLinks:true,
  menu:false,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "APPOINTMENT":
      return {
        ...state,
        appointments: action.payload
      };
      case "MENU":
      return {
        ...state,
        menu: action.payload
      };
    case "SHOW":
      return {
        ...state,
        showModel: action.payload
      };
      case "SHOWCONTACTTMODEL":
        return {
          ...state,
          showContactSupport: action.payload
        };
      case "SHOWPASSWORD":
      return {
        ...state,
        showPasswordModel: action.payload
      };
    case "NOTIFICATION":
      return {
        ...state,
        notification: !(state.notification)
      };
    case "SHOWAPPOINTMENT":
      return {
        ...state,
        showAppointmentModel: action.payload
      }
    case "SHOWDELETEMODEL":
      return {
        ...state,
        showDeleteModel: action.payload
      }
    case "SHOWQUICKPATIENTMODEL":
      return {
        ...state,
        showQuickpatient: action.payload
      }
    case "SHOWCONFIRMSCHEDULE":
      return {
        ...state,
        showConfirmschedule: action.payload
      }
    case "SETPHYSICIAN":
      return {
        ...state,
        physicianId: action.payload
      }
    case "VIEWAPPOINTMENT":
      return {
        ...state,
        viewAppointment: action.payload
      }
    case "SIDEBAR":
      return{
        ...state,
        showSidebar:!(state.showSidebar)
      }
      case "FILTERBAR":
      return{
        ...state,
        showFilterBar:!(state.showFilterBar)
      }
      case "PATIENTPROFILE":
        return{
          ...state,
          patientProfile:!(state.patientProfile)
        }
        case "SETAVAILABILITY":
        return{
          ...state,
          showSetAvailability:!(state.showSetAvailability)
        }
      case "ADDCOLUMNS":
      return{
        ...state,
        showcolumn:!(state.showcolumn)
      }
      case "UPDATEDATESMODEL":
        return{
          ...state,
          updateDatesModel:!(state.updateDatesModel)
        }
      case "UPDATEACCESSLINKS":
        return{
          ...state,
          accessLinks:!(state.accessLinks)
        }
    default:
      return state;
  }
};

export { reducer };