import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { getfList } from "./ConfigFiles/SharedFunctions";
import { urls } from "./ConfigFiles/Url";
import Forbidden from "./Forbidden/Forbidden";
import { RiArrowLeftRightLine } from "react-icons/ri";
import { MenuAction } from "./Store/calender/action";
import { useDispatch, useSelector } from "react-redux";

import SchedyoPatientPrivateRoute from "./PrivateRoutes/SchedyoPatientPrivateRoute";
import SchedyoPrivateRoute from "./PrivateRoutes/SchedyoPrivateRoute";
import CryptoJS from "crypto-js";

const LoginComponent = lazy(() => import("./Schedyo/Login"));
const BookAppComponent = lazy(() => import("./Schedyo/LandingPages/BookAppPatient"));
const CreatePasswordComponent = lazy(() => import("./Schedyo/CreatePassword"));
const PatientPortalComponent = lazy(() => import("./Schedyo/PatientPortal/Patient"));
const SchedyoRoutingConfigComponent = lazy(() => import("./Schedyo/RoutingConfig"));
const HomeComponent = lazy(() => import("./Schedyo/LandingPages/Home"));
const AppointmentComponent = lazy(() => import("./Schedyo/LandingPages/appointmentRequest"));
const AppointmentViewComponent = lazy(() => import("./Schedyo/LandingPages/AppointmentView"));
const DoctorListComponent = lazy(() => import("./Schedyo/LandingPages/DoctorList"));

function App() {
  const accessLinksStatus = useSelector((state) => state.accessLinks);
  const dispatch = useDispatch();
  const menub = useSelector((state) => state.menu);

  const accesslinks = async () => {
    try {
      let res = await getfList(urls.authenticate.getAccessLinks);
      console.log(res, "res");
      let links = res?.map((e) => e.linkName);
      let encryptedtext = CryptoJS.AES.encrypt(
        JSON.stringify(links),
        "Schedyo123"
      ).toString();
      sessionStorage.setItem("accessLinks", encryptedtext);
    } catch (error) {
      console.error("Error fetching access links:", error);
    }
  };

  const toggoleMenu = () => {
    dispatch(MenuAction(!menub));
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "Default Title";
  }, []);

  useEffect(() => {
    accesslinks();
  }, [accessLinksStatus]);

  return (
    <>
      <Suspense fallback={<div>Loading ....</div>}>
        <Router>
          <Routes>
            {/* <Route path="" element={<LoginComponent />} /> */}
            <Route path="" element={<HomeComponent />} />
            <Route path="forbidden" element={<Forbidden />} />
            <Route path="/doctorList/:facilityId/:specialityId" element={<DoctorListComponent />} />
            <Route path="/appointmentView" element={<AppointmentViewComponent />} />
            <Route path="/appointment" element={<AppointmentComponent />} />
            <Route path="login" element={<LoginComponent />} />
            <Route path="bookappointment" element={<BookAppComponent />} />
            <Route path="createpassword/:token" element={<CreatePasswordComponent />} />
            <Route path="forgotpassword/:token" element={<CreatePasswordComponent />} />
            <Route path="scheduler/*" element={<SchedyoRoutingConfigComponent />} />
            <Route path="patient/*" element={<SchedyoPatientPrivateRoute><PatientPortalComponent /></SchedyoPatientPrivateRoute>}/>
            <Route path="*" element={<LoginComponent />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
