export const Appointment = (payload) => ({
    type:"APPOINTMENT",
    payload,
})
export const MenuAction = (payload) => ({
    type:"MENU",
    payload,
})

export const showAction = (payload) =>({
    type:"SHOW",
    payload,
})

export const showPasswordAction = (payload) =>({
    type:"SHOWPASSWORD",
    payload,
})

export const showAppointmentAction = (payload) =>({
    type:"SHOWAPPOINTMENT",
    payload,
})

export const showContactSupport = (payload) =>({
    type:"SHOWCONTACTTMODEL",
    payload,
})

export const showDeleteAction = (payload) =>({
    type:"SHOWDELETEMODEL",
    payload,
})

export const showQuickpatientAction = (payload) =>({
    type:"SHOWQUICKPATIENTMODEL",
    payload,
})

export const showConfirmScheduleAction = (payload) => ({
    type:"SHOWCONFIRMSCHEDULE",
    payload,
})

export const setPhysicianIdAction = (payload) => ({
    type:"SETPHYSICIAN",
    payload
})

export const viewAppointmentAction = (payload) =>({
    type:"VIEWAPPOINTMENT",
    payload
})

export const viewSidebarAction = () =>({
    type:"SIDEBAR"
})

export const viewFilterbarAction = () =>({
    type:"FILTERBAR"
})

export const showNotificationAction = () =>({
    type:"NOTIFICATION"
})

export const showTablePopupAction = () =>({
    type:"ADDCOLUMNS"
})

export const showPatientProfileAction = () =>({
    type:"PATIENTPROFILE"
})

export const showSetAvailabilityAction = () =>({
    type:"SETAVAILABILITY"
})

export const showUpdateDatesAction = () =>({
    type:"UPDATEDATESMODEL"
})

export const UpdateAccessLinksAction = () =>({
    type:"UPDATEACCESSLINKS"
})