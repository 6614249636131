import axios from "axios";
import { baseURL } from "../ConfigFiles/Url";
import "./index.css";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let load = 0
const instance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: baseURL,
});
instance.interceptors.request.use(
  (config) => {
    load++
    document.body.classList.add("loading-indicator");
    const token = sessionStorage.getItem("token");
    let t1 = "Bearer " + token
    if (token) {
      config.headers["Authorization"] = t1;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers["Timezone"] = timezone; // Add the timezone header
    // config.headers['Access-Control-Allow-Origin'] = "*"
    return config;
  },
  (error) => {
    load--
    if (load == 0) {
      document.body.classList.remove("loading-indicator");
    }
    return Promise.reject(error);
  }
);
//Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    load--
    if (load == 0) {
      document.body.classList.remove("loading-indicator");
    }
    return response;
  },
  (error) => {
    console.log(error);
    load--
    if (load == 0) {
      document.body.classList.remove("loading-indicator");
    }
    // document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

export const get = async (url, params = "") => {
  return await instance.get(`${url}${params}`);
};

export const post = async (url, data = {}) => {
  try {
    return await instance.post(`${url}`, data);
  } catch (e) {
    return e;
  }
};
export default instance;
